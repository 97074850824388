import { Address, File } from "@/resources/interfaces";
import moment from "@/helpers/moment";
import { VueConstructor } from "vue";
import { config } from "@/helpers/loadConfig";
// @ts-ignore
import Sortable from "sortablejs";
import { downloadUrl } from "@/helpers/file";

const CommonFiltersPlugin = {
  install(Vue: VueConstructor) {
    Vue.filter("date", (value: any, format: string, fromFormat?: string) => {
      if (!value) {
        return null;
      }
      if (value.getTime && isNaN(value.getTime())) return "-";
      return moment(value, fromFormat)
        .tz("America/New_York")
        .format(format || "MM/DD/Y HH:mm");
    });

    Vue.filter("birthday", (value: any, till?: any) => {
      const s = moment(value);
      if (!value || !s.isValid()) return "";
      const d = moment.duration(moment(till).diff(s));
      const y = d.years(),
        m = d.months();
      return (
        s.format("MM/DD/Y") +
        ` (${y} ${y > 1 ? "yrs" : "yr"} ${m} ${m > 1 ? "mos" : "mo"})`
      );
    });

    Vue.filter("dateU2S", (value: string) => {
      if (!value) {
        return null;
      }
      if (value.match(/(\d+)\/(\d+)\/(\d+)/))
        return value.replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");
      return moment(value).format("Y-MM-DD");
    });

    Vue.filter("timeDiff", (value: any) => {
      if (!value) {
        return null;
      }
      return moment(value).fromNow();
    });

    Vue.filter("duration", (value: any) => {
      return moment.duration(value).humanize();
    });

    Vue.filter("round", (value: any, digits: number, lazyPrecision = false) => {
      if (isNaN(value) || value === null) return "-";
      if (!value.toFixed) {
        return (0).toFixed(digits);
      }
      let fixed = value.toFixed(digits || 0);
      if (lazyPrecision) {
        fixed = +fixed;
      }
      return fixed;
    });

    Vue.filter(
      "percentage",
      (value: any, digits = 1, lazyPrecision = false) => {
        if (isNaN(value) || value === Infinity || value === null) return "-";
        if (!value.toFixed) {
          return (0).toFixed(digits) + "%";
        }
        let fixed = (value * 100).toFixed(digits || 0);
        if (lazyPrecision) {
          fixed = (+fixed).toString();
        }
        return fixed + "%";
      }
    );

    Vue.filter(
      "currency",
      (
        value: any,
        precision: number = 2,
        lazyPrecision: boolean = false,
        kmb = false,
        comma = ",",
        currency = "$"
      ) => {
        let sign = "";
        if (value === null || isNaN(value)) return "-";
        if (value < 0) {
          value = -value;
          sign = "-";
        }
        let suffix = "";
        if (kmb) {
          if (value > 5e2) {
            value /= 1000;
            suffix = "k";
          }
          if (value > 5e2) {
            value /= 1000;
            suffix = "m";
          }
        }
        let fixed = (+value).toFixed(precision);
        if (lazyPrecision) fixed = (+fixed).toString();
        fixed = fixed
          .split(".")
          .map((seg, index) => {
            if (index) return seg;
            return seg.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${comma}`);
          })
          .join(".");
        return sign + currency + fixed + suffix;
      }
    );

    Vue.filter(
      "fileSize",
      (bytes: number, fixed = 1, spec?: "si" | "iec" | "jedec") => {
        if (!bytes) return "";
        const si = {
          radix: 1e3,
          unit: ["b", "kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"],
        };
        const iec = {
          radix: 1024,
          unit: ["b", "Kib", "Mib", "Gib", "Tib", "Pib", "Eib", "Zib", "Yib"],
        };
        const jedec = {
          radix: 1024,
          unit: ["b", "Kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"],
        };
        const SPECS = { si, iec, jedec };
        bytes = Math.abs(bytes);
        const { radix, unit } = SPECS[spec || "si"];
        let loop = 0;
        while (bytes >= radix) {
          bytes /= radix;
          ++loop;
        }
        return `${bytes.toFixed(fixed)} ${unit[loop]}`;
      }
    );

    Vue.filter("ein", (value: string) => {
      if (!value) return;
      const match = value.match(/^(\d{2})(\d{7})?$/);
      if (match) {
        return `${match[1]}-${match[2] || ""}`;
      }
      return value;
    });

    Vue.filter("ssn", (value: string) => {
      if (!value) return;
      const match = value.match(/^(\d{3})-?(\d{2})?-?(\d{4})?$/);
      if (match) {
        let out = match[1] + "-";
        if (match[2]) {
          out += match[2] + "-";
        }
        if (match[3]) {
          out += match[3];
        }
        return out;
      }
      return value;
    });

    Vue.filter("url", (value: string) => {
      if (value && !value.match(/^https?\/\//)) {
        return "http://" + value;
      }
      return value;
    });

    Vue.filter("downloadUrl", (file: File, preview = false) => {
      return downloadUrl(file, preview);
    });

    Vue.filter("appStatus", (value: number) => {
      return Vue.prototype.$appStatuses[value + 1] || "-";
    });

    Vue.filter("appCondition", (value: string) => {
      return Vue.prototype.$appConditions[value] || "-";
    });

    Vue.filter("dayOfWeek", (value: number, level = 0) => {
      const names = [
        ["SU", "MO", "TU", "WE", "TH", "FR", "SA"],
        ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
        [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
      ];
      return names[level][value];
    });

    Vue.filter("entityTagColor", (value: String) => {
      const { entityTags = [] } = config;
      const tag = entityTags.find((t) => t.name === value);
      if (tag) return tag.color;
      return "light-grey";
    });

    Vue.filter("entityTagIcon", (value: String) => {
      const { entityTags = [] } = config;
      const tag = entityTags.find((t) => t.name === value);
      if (tag) return tag.icon;
      return "";
    });

    Vue.filter("appTagColor", (value: String) => {
      const { appTags = [] } = config;
      const tag = appTags.find((t) => t.name === value);
      if (tag) return tag.color;
      return "light-grey";
    });

    Vue.filter("joinAddress", (value: Address, shorten = false) => {
      if (
        !value ||
        (!value.line1 &&
          !value.line2 &&
          !value.city &&
          !value.state &&
          !value.zipCode)
      )
        return "-";
      if (shorten) return `${value.city || ""}, ${value.state || ""}`;
      return `${value.line1 || ""}${value.line2 ? ", " + value.line2 : ""}, ${
        value.city || ""
      }, ${value.state || ""}, ${value.zipCode || ""}`;
    });

    Vue.filter("paymentTypeName", (value: string) => {
      return (
        Vue.prototype.$paymentTypeName[value] ||
        `${value.toUpperCase()} (Deprecated)`
      );
    });

    Vue.filter("lenderName", (value: string) => {
      const lender = config.lenders?.find((l) => l.code === value);
      if (!lender) return "";
      let name = lender.name;
      if (lender.isServicing && process.env.VUE_APP_KAM !== "1") {
        name += " (servicing)";
      }
      return name;
    });

    Vue.directive("tableHeaderDraggable", {
      bind: function (el: HTMLElement, binding: any, vnode: any) {
        // const element = document.querySelector(".v-data-table-header>tr");
        const element = el.querySelector(".v-data-table-header>tr");
        const List = vnode.context;
        const DataTable = vnode.componentInstance;
        Sortable.create(element, {
          filter: "tr>:first-child",
          onMove: function (e: any) {
            const ariaLabel = e.related.ariaLabel;
            return !!ariaLabel;
          },
          onEnd: ({
            newIndex,
            oldIndex,
          }: {
            newIndex: number;
            oldIndex: number;
          }) => {
            if (DataTable.showSelect) {
              if (oldIndex === 0 || newIndex === 0) return;
              oldIndex--;
              newIndex--;
            }
            if (!List.currentListTemplate) return;
            const headers = List.selectedColumns.map(
              (c: any) => c?.value || ""
            );
            const [dragging] = headers.splice(oldIndex, 1);
            headers.splice(newIndex, 0, dragging);
            List.$set(List.currentListTemplate, "headers", []);
            List.$nextTick().then(() => {
              if (!List.currentListTemplate) return;
              List.$set(List.currentListTemplate, "headers", headers);
            });
          },
        });
      },
    });
  },
};

export default CommonFiltersPlugin;
