import { VueConstructor } from "vue";
import { EntityLink, FeeType, Role, User } from "@/resources/interfaces";
import Component from "vue-class-component";
import VueClipboard from "vue-clipboard2";

const GlobalLibrariesPlugins = {
  install(Vue: VueConstructor) {
    Component.registerHooks([
      "beforeRouteEnter",
      "beforeRouteUpdate",
      "beforeRouteLeave",
    ]);

    Vue.use(VueClipboard);

    Vue.config.errorHandler = (err, vm, info) => {
      console.error(err.message);
      console.error(err.stack);
    };

    // global library setup
    Object.defineProperty(Vue.prototype, "$version", {
      get() {
        return process.env.VERSION;
      },
    });

    Object.defineProperty(Vue.prototype, "$homeUrl", {
      get() {
        return window.location.href.match(/^.*\/\/.*?\//)?.[0];
      },
    });

    Object.defineProperty(Vue.prototype, "$isDev", {
      get() {
        return process.env.NODE_ENV === "development";
      },
    });

    Object.defineProperty(Vue.prototype, "$isProd", {
      get() {
        return process.env.VUE_APP_BUILD_MODE === "production";
      },
    });

    Object.defineProperty(Vue.prototype, "$isKAM", {
      get() {
        return process.env.VUE_APP_KAM === "1";
      },
    });

    Object.defineProperty(Vue.prototype, "$pageTitle", {
      get() {
        return this.$root.pageTitle;
      },
      set(v) {
        this.$root.pageTitle = v;
      },
    });

    Object.defineProperty(Vue.prototype, "$pendingRequests", {
      get() {
        return this.$root.pendingRequests;
      },
      set(val) {
        this.$root.pendingRequests = val;
      },
    });

    Object.defineProperty(Vue.prototype, "$config", {
      get() {
        return this.$root.config;
      },
      set(v) {
        this.$root.config = v;
      },
    });

    Object.defineProperty(Vue.prototype, "$notify", {
      get() {
        return (message: string, type: string, timeout?: number) => {
          if (!timeout) {
            const lines = message.split("\n").length;
            const chars = message.length;
            timeout = chars * 40 + (lines + 3) * 1000;
            // console.log("lines:", lines, "chars:", chars, "timeout:", timeout);
          }
          this.$store.commit("ADD_NOTIFICATION", { message, type, timeout });
        };
      },
    });

    const can = function (this: User, cap: string) {
      const roleCaps: Record<string, string> = {
        [Role.ADMIN]: ".*",
        [Role.SCRUBBER]:
          "view-(dashboard|iso|business|submission|advance)|edit-application|add-business",
        [Role.UWER]:
          "view-(dashboard|iso|business|submission|advance)|create-offer|edit-application|draft-contract",
        [Role.REP]:
          "view-(submission|advance|business|iso)|review-mod-request|fine-tune",
        [Role.DRAFTER]:
          "view-(submission|advance|business)|edit-application|review-mod-request|draft-contract",
        [Role.ISO]: "view-(submission|advance)|add-(business)",
        [Role.CUSTOMER_SERVICE]:
          "view-(iso|business|submission|advance|cs-task)|create-cs-task|cs-notify",
        [Role.TREASURY]:
          "view-(iso|business|syndicator|submission|advance|accounting|cs-task)|fine-tune|activation|create-cs-task",
        [Role.FINAL_REVIEWER]:
          "view-(dashboard|iso|business|submission|advance|cs-task)|create-offer|edit-application|draft-contract",
        [Role.ACCOUNTING]:
          "view-(dashboard|iso|business|syndicator|submission|advance|accounting|cs-task)|edit-application|fine-tune",
      };
      return this.role && cap.match(new RegExp(`^${roleCaps[this.role]}$`));
    };

    Object.defineProperty(Vue.prototype, "$user", {
      get() {
        if (!this.$root.config.user) {
          this.$root.config.user = {};
        }
        this.$root.config.user.can = can;
        return this.$root.config.user;
      },
      set(v) {
        this.$root.config.user = v;
      },
    });

    Object.defineProperty(Vue.prototype, "$appStatuses", {
      get() {
        return [
          "Leads",
          "Submitted",
          "Decisioned",
          "Funded",
          "Closed",
          "Legal",
        ];
      },
    });

    Object.defineProperty(Vue.prototype, "$appConditions", {
      get() {
        return {
          S1: "New",
          S2: "In Intake",
          S3: "In Intake -- Missing Info",
          S4: "In UW",
          D0: "Declined (Intake)",
          D30: "Decisioning",
          D1: "Approved",
          D3: "Approved / Mixed",
          D5: "Declined",
          D20: "Offer Sent",
          D21: "Decline Sent",
          D22: "Submission Expired",
          D23: "Offer Expired",
          D24: "Contract Expired",
          D7: "Revision Requested",
          D8: "Contract Requested, Rep Review",
          D9: "Rejected in Doc Request",
          D12: "Contract Requested",
          D10: "Contract Out",
          D11: "Contract In",
          D13: "Final Review",
          D14: "Ready for Funding",
          D15: "Ready for Funding / Pending Info",
          D16: "Rejected After Contract",
          D17: "Ready for Funding / Peer Review",
          F1: "Performing (First 30 Days)",
          F2: "Performing",
          F3: "Written Off (Paying)",
          F4: "Early Warning",
          F5: "Collections",
          F6: "Restructured (Non Acc)",
          F7: "Restructured (Acc)",
          F8: "Performing (Was Collection)",
          F9: "Performing (Was Restructured)",
          F10: "Disaster Relief",
          F11: "Performing (Was Defaulted)",
          F12: "Default",
          F13: "Collections (Was Defaulted)",
          F14: "Restructured (Acc, Was Defaulted)",
          F15: "Restructured (Non Acc, Was Defaulted)",
          L0: "Legal (Pending Assignment)",
          L1: "Legal (Restructured)",
          L2: "Legal (Defaulted)",
          C1: "Paid In Full",
          C2: "Paid Epo",
          C3: "Written Off",
          C4: "Settlement",
          C5: "Returned",
          C6: "Ignore",
          C7: "Sold",
        };
      },
    });

    Object.defineProperty(Vue.prototype, "$paymentTypeName", {
      get() {
        return {
          sd: "Syndicator Deposit",
          sw: "Syndicator Withdraw",
          st: "Syndicator Account Transfer",
          s: "Syndication",
          sf: "Syndication Fee",
          sic: "Syndication ISO Commission",
          src: "Syndication Rep Commission",
          f: "Merchant Fund",
          mp: "Merchant Payback",
          of: "Originating Fee",
          uf: "Underwriting Fee",
          tf: "Tech Fee",
          pf: "Post Funding Fee",
          sp: "Syndication Payout",
          ic: "ISO Comm",
          if: "ISO Fee",
          rc: "Rep Comm",
          rf: "Rep Fee",
          "3lc": "3rd Party Legal Charge",
          s3lc: "Syndication 3rd Party Legal Charge",
          "3p": "3rd Party Payout",
          s3p: "Syndication 3rd Party Payout",
          icc: "ISO Comm Clawback",
          sicc: "Syndication ISO Comm Clawback",
          rcc: "Rep Comm Clawback",
          srcc: "Syndication Rep Comm Clawback",
          vb: "VB",
          svt: "Syndication VB True Ups",
          vr: "VB Reserve",
          vbc: "VB Clawback",
          svbc: "Syndication VB Clawback",
        };
      },
    });

    Object.defineProperty(Vue.prototype, "$feeTypes", {
      get() {
        return Object.values(FeeType);
      },
    });

    Object.defineProperty(Vue.prototype, "$feeTypeName", {
      get() {
        return {
          nsf: "NSF",
          default: "Rej-Block ACH / Def Fee",
          "bank change": "Bank Change",
          stacking: "Stacking",
          wire: "Wire",
          modification: "Modification",
          "pay off letter": "Pay Off Letter",
          "legal billing": "Legal Billing",
          "ad-hoc": "Ad-Hoc",
          bounce: "Bounce",
        };
      },
    });

    Object.defineProperty(Vue.prototype, "$noop", {
      get() {
        return () => {};
      },
    });

    Object.defineProperty(Vue.prototype, "$clipboard", {
      get() {
        return (v: string, name: string = "Text") => {
          this.$copyText(v);
          this.$notify(`${name} copied to clipboard.`, "success");
        };
      },
    });

    Object.defineProperty(Vue.prototype, "$typingFormat", {
      get() {
        return {
          date(s: string) {
            if (!s) return;
            if (s.match(/^\d{2}$/) || s.match(/^\d{2}\/\d{2}$/)) return s + "/";
            s = s.replace(/\/{2,}/g, "/");
            return s;
          },
          ssn(s: string) {
            if (!s) return;
            if (s.match(/^\d{3}$/) || s.match(/^\d{3}-\d{2}$/)) return s + "-";
            s = s.replace(/-{2,}/g, "-");
            return s;
          },
          ein(s: string) {
            if (!s) return;
            if (s.match(/^\d{2}$/)) return s + "-";
            s = s.replace(/-{2,}/g, "-");
            return s;
          },
        };
      },
    });

    Object.defineProperty(Vue.prototype, "$validator", {
      get() {
        return {
          date(s: string) {
            if (s && s.match(/^\d{2}\/\d{2}\/\d{4}$/)) return true;
            else return "Date format should be MM/DD/YYYY";
          },
          ssn(s: string) {
            if (s && s.match(/^\d{3}-\d{2}-\d{4}$/)) return true;
            else return "SSN format should be 000-00-0000";
          },
          ein(s: string) {
            if (s && s.match(/^\d{2}-\d{7}$/)) return true;
            else return "EIN format should be 00-0000000";
          },
          max(max: number) {
            return (v: any) => {
              if (v <= max || isNaN(max)) return true;
              if (isNaN(v)) return "Not valid number.";
              return `Maximum allowed is ${max}.`;
            };
          },
        };
      },
    });

    Object.defineProperty(Vue.prototype, "$getUserEntityLinkField", {
      get() {
        return (
          user: User,
          entityCollection: "iso" | "business" | "syndicator",
          entityId: string,
          field: string
        ) => {
          return user.entityLinks?.find(
            (link) => (link[entityCollection] = entityId)
          )?.[field as keyof EntityLink];
        };
      },
    });

    Object.defineProperty(Vue.prototype, "$getCellWidth", {
      get() {
        return (width?: string | number) => {
          if (!width) return "";
          if (!isNaN(+width)) width += "px";
          return `width: ${width}`;
        };
      },
    });
  },
};

export default GlobalLibrariesPlugins;
